import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { DataOrderService } from 'app/shared/services/data-order.service';
import { ProgramService, ProgrammeSubmissionData, UpdateProgramProgressRequest } from 'app/shared/services/program.service';
import { ListPharmaciesDialog } from 'app/views/products/active/active.component';
import { Pharmacy } from 'app/views/reports/reports.component';

export interface Incentive {
  id: number;
  title: string;
  content?: string;
  progress: number;
  maxProgress: number;
  type?: string | null;
  action?: string;
  additionalContent?: boolean;
  actionMeta?: string;
  actionLoading?: boolean
  showProgress: boolean
}

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit, AfterViewInit {
  incentives: Incentive[] = []
  pharmacies: Pharmacy[] = []
  pharmacy: Pharmacy
  locationId: number
  title = ''
  description = ''
  image = ''
  programmeSlug: string = '';
  resources: Incentive[] = []
  isAdmin = false
  loading = false
  constructor(
    private incentiveService: ProgramService,
    private auth: JwtAuthService,
    private orderService: DataOrderService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngAfterViewInit() {
    this.getData()
  }

  async getProgress() {
    return new Promise((resolve, reject) => {
      this.incentiveService.getProgramProgress({
        programme_slug: this.programmeSlug,
        location_id: this.locationId
      }).subscribe(res => {
        this.title = res.name
        this.description = res.description
        this.image = res.image
        this.resources = res.resources.map(question => ({
          id: question.question_id,
          maxProgress: 0,
          progress: 0,
          title: question.question,
          type: question.question_type,
          content: question.subtext,
          action: question.answer_options?.[0]?.option_choice_name,
          actionMeta: question.answer_options?.[0]?.option_text,
          additionalContent: ['Quiz'].includes(question.question_type),
          actionLoading: false,
          isResource: true,
          showProgress: false
        }))
        this.incentives = res.programme_progress.map(item => {
          return {
            id: item.id,
            maxProgress: item.max_progress,
            progress: item.progress,
            title: item.question,
            type: item.question_type,
            content: item.subtext,
            action: item.answer_options?.[0]?.option_choice_name,
            actionMeta: item.action_link,
            additionalContent: ['Quiz'].includes(item.question_type),
            actionLoading: false,
            showProgress: true
          }
        })
        resolve(true)
      }, () => {
        this.resources = []
        this.incentives = []
        reject()
      })
      })
    }

    async getData() {
      try {
      await this.getProgress()
    } catch (error) {
      return 
    }

    if (this.incentives.length === 0) return;
    
    if (this.programmeSlug !== 'pharmacy-program') return;

    this.orderService.getOrderCount({
      source: `Quitmate-Pharmacy-Telehealth-${this.locationId}`,
      location_id: this.locationId,
      order_class: 'Telehealth Online,Telehealth In Store,Telehealth Claim',
      order_status: 'Completed,Cancelled',
    })
      .subscribe(response => {
        const incentive = this.incentives[this.incentives.length - 1]
        this.submitIncentive({
          incentive_id: incentive.id,
          progress: response.count.toString(),
          response: {} as ProgrammeSubmissionData
        })
        incentive.progress = response.count
      }, err => {
        this.loading = false
      })
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: { programmeSlug?: string, locationId: number }) => {
      this.programmeSlug = params.programmeSlug
      this.locationId = params.locationId ?? this.auth.user.locationid
      this.getData()
    })
    this.isAdmin = this.auth.isAdmin()
  }

  handleAction(e: UpdateProgramProgressRequest) {
    this.submitIncentive(e)
  }

  async submitIncentive(req: UpdateProgramProgressRequest) {
    this.loading = true
    await new Promise((resolve, reject) => this.incentiveService.updateProgramProgress(req).subscribe(() => resolve(true), reject))

    this.getProgress()
    this.loading = false
  }

  async changePharmacy() {
    this.loading = true
    if (this.pharmacies.length === 0) {
      await new Promise((resolve, reject) => this.orderService.getPharmacyList({ quitmate_locations: 1 }).subscribe((res: Pharmacy[]) => {
        this.pharmacies = res
        resolve(null)
      }, reject));
    }

    this.loading = false

    const dialogRef = this.dialog.open(ListPharmaciesDialog, {
      width: '30%',
      data: this.pharmacies,
    });

    dialogRef.afterClosed().subscribe((result: Pharmacy) => {
      if (!result || result?.locationid == this.locationId) return;

      const path = this.route.snapshot.url.slice(0, 2).map(route => route.path).join('/')
      this.locationId = result.locationid
      this.router.navigate([`${path}/${this.locationId}`])
      this.pharmacy = result
      this.getData()
    });
  }

}

export const config = {
  // apiUrl: 'http://ui-lib-demo-api.herokuapp.com',
  apiUrl:'https://test.medmate.com.au/uat/api/v1/',
  apiUrl1:'https://test.medmate.com.au/uat/api/v3/',

  rendrUrl: 'https://retailer.rendr.delivery/',

  authRoles: {
    admin: ['Admin'], // Only Admin has access
    portalUser: ['Admin', 'Portal User'],
    user: ['Admin', 'User'], // Only Admin & User has access
  }
}
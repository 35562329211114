import { Component, OnInit, Input } from '@angular/core';
import { User } from 'app/shared/models/user.model';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { NavigationService } from 'app/shared/services/navigation.service';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
  styleUrls: ['./sidenav.component.spec.scss']

})

export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  user: User = {}
  menuCounts:any;
  Sign='plus';
  OrderDetails:any[];
  
  constructor(private navigationService: NavigationService, private jwtAuth: JwtAuthService) {
    this.user = this.jwtAuth.getUser()
  }
  ngOnInit() {}

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        {name: 'SUBITEM', state: 'cards'},
        {name: 'SUBITEM', state: 'buttons'}
      ]
    });
  }
}
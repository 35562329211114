import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private jwtAuth: JwtAuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var ModifiedReq;

    if (this.jwtAuth.getJwtToken()) {

      ModifiedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.jwtAuth.getJwtToken()}`
        },
      });
    //  ModifiedReq=req.clone({
    //     headers:req.headers.append("Auth",token)
    //   })

    } else {

      ModifiedReq = req;
      
    }
    return next.handle(ModifiedReq);
  }
}

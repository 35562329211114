import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { HttpHeaders, HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { catchError, tap, map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Observable, pipe, of, throwError, Subject } from 'rxjs';
import { environment } from 'environments/environment';

import { RoutePartsService } from './shared/services/route-parts.service';

import { filter } from 'rxjs/operators';
import { UILibIconService } from './shared/services/ui-lib-icon.service';
import { JwtAuthService } from './shared/services/auth/jwt-auth.service';
import { NavigationService } from './shared/services/navigation.service';
import { PortalSettingsService } from './shared/services/portal-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Medmate Portal';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
    private http: HttpClient, 
    private httpBackend: HttpBackend,
    private jwtAuth: JwtAuthService,
    private navService: NavigationService,
    private portalSettingsService: PortalSettingsService
  ) {
    iconService.init()
  }

  ngOnInit() {
    
    this.portalSettingsService.portalSettings.subscribe(settings => {
      this.appTitle = settings.app_title
    })
    
    this.changePageTitle();
    if(this.jwtAuth.getUser().role === 'Admin') {
      this.navService.publishNavigationChange('admin-menu')
    } else if(this.jwtAuth.getUser().role === 'User') {
      this.navService.publishNavigationChange('user-menu')
    } else if(this.jwtAuth.getUser().role === 'Portal User') {
      this.navService.publishNavigationChange('portal-user-menu')
    } else if (this.jwtAuth.getUser().role === 'Business User') {
      this.navService.publishNavigationChange('business-user-menu')
    } else if (this.jwtAuth.getUser().role === 'Driver') {
      this.navService.publishNavigationChange('driver-menu')
    }
  }

  ngAfterViewInit() {
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`});
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../services/theme.service';
import { JwtAuthService } from '../../../services/auth/jwt-auth.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './driver-layout.template.html',
})
export class DriverLayoutComponent implements OnInit {

  public scrollConfig = {}
  public layoutConf: any = {};
  public adminContainerClasses: any = {};
  constructor(
    public translate: TranslateService,
    public themeService: ThemeService,
    private jwtAuth: JwtAuthService,
  ) {
    // Check Auth Token is valid
    this.jwtAuth.checkTokenIsValid().subscribe();

    // Translator init
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {
  }

}
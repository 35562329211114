import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgrammeSubmissionData, UpdateProgramProgressRequest } from 'app/shared/services/program.service';
import { Incentive } from '../program/program.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaProps, IframeComponent } from 'app/shared/components/media/video.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-incentive-card',
  templateUrl: './incentive-card.component.html',
  styleUrls: ['./incentive-card.component.scss']
})
export class IncentiveCardComponent implements OnInit {

  @Input() incentive: Incentive
  @Input() additionalContent: boolean = false
  @Input() completeable: boolean = false
  @Input() isResource: boolean = false
  @Output() actionComplete = new EventEmitter<UpdateProgramProgressRequest>()

  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
  }

  completed() {
    return this.incentive.progress === this.incentive.maxProgress
  }

  handleFileChange(e: Event) {
    const target = e.target as HTMLInputElement
    if (target.files.length === 0) return
    this.actionComplete.emit({
      incentive_id: this.incentive.id,
      response: {
        answer: '',
        question: this.incentive.title,
        response_type: this.incentive.type,
      },
      file: target.files[0]
    })
  }

  markComplete() {
    if (this.completed() || !this.completeable) return;
    
    this.actionComplete.emit({
      incentive_id: this.incentive.id,
      response: {} as ProgrammeSubmissionData,
      progress: this.incentive.maxProgress.toString() 
    })
  }

  handleAction() {
    this.incentive.additionalContent = !this.incentive.additionalContent
  }

  openVideo() {
    if (!this.incentive.action) return;

    this.dialog.open(IframeComponent, {
      width: '50%',
      height: '80%',
      data: {
        src: this.incentive.actionMeta
      } as MediaProps
    })
  }

  toInternalLink() {
    if (!this.incentive.actionMeta) return;

    this.router.navigate([this.incentive.actionMeta])
  }
}

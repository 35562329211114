<div mat-dialog-content>
  <h2>{{data.ItemName}}</h2>
  <div class="price-title">
    <h3> Set a price for this item</h3>
    <mat-icon class="mm-text-primary my-price-info-icon" style="cursor: pointer;" (click)="openPharmacyPriceInfoDialog()">info</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="price-container">
    <div class="unit-price-title">
      My Unit Price
    </div>
    <div class="customer-price-title">
      Customer Price
    </div>
    <!-- medmate prices -->
    <div class="medmate-store-title">Medmate Store</div>
    <span class="input-symbol-dollar medmate-unit-price">
      <input class="mm-text-input" type="number" min="0" [formControl]="medmateUnitPrice">
    </span>
    <div class="medmate-customer-price">${{calculatedMedmateCustomerPrice}}</div>
    <!-- uber prices -->
    <div class="uber-store-title uber">Uber Store</div>
    <span class="input-symbol-dollar uber-unit-price uber">
      <input class="mm-text-input uber" type="number" min="0" [formControl]="uberUnitPrice">
    </span>
    <div class="uber-customer-price uber">${{calculatedUberCustomerPrice}}</div>
    <!-- <div class="price-input">
        <label for='price'>Set a price for this item:</label>
        <input name='price' type="number" min="0"  class="mm-text-input fee-edit-input" [(ngModel)]='price'>
    </div> -->
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-flat-button class="mm-bg-pink mm-text-white mm-mr-2" (click)="onNoClick()">Cancel</button>
  <button mat-flat-button class="mm-bg-green mm-text-white mm-mt-10" [disabled]="medmateUnitPrice.value<=0" (click)="showConfirm = true" *ngIf="showConfirm === false else confirm">Save</button>
  <ng-template #confirm>
    <button mat-flat-button class="mm-bg-green mm-text-white" (click)="onYesClick()">Are you sure?</button>
  </ng-template>
</mat-dialog-actions>

<!-- <div mat-dialog-content>
  <mat-table [dataSource]="data">
    <ng-container matColumnDef="priceName">
      <mat-header-cell *matHeaderCellDef class="small">Title</mat-header-cell>
      <mat-cell *matCellDef="let element;">{{ element.title }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="unitPrice">
      <mat-header-cell *matHeaderCellDef class="small">My Unit Price</mat-header-cell>
      <mat-cell *ngIf='row.title=="Medmate Store"'>{{ medmateUnitPrice }}</mat-cell>
      <mat-cell *ngIf='row.title=="Uber Store"'>{{ uberUnitPrice }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="customerPrice">
      <mat-header-cell *matHeaderCellDef class="small">
        <span>Customer Price</span>
        <mat-icon class="mm-text-primary" style="cursor: pointer;" (click)="openPharmacyPriceInfoDialog()">info</mat-icon>
      </mat-header-cell>
      <mat-cell [ngClass]="{'price-cell': element.title !== 'Third Party Price'}" *matCellDef="let element; let i = index">
        <div [ngClass]="{'price-display': element.title !== 'Third Party Price'}" *ngIf="!element.editPrice else priceInput" (click)="toggleEditPrice(element)">${{ element.price }}</div>
        <ng-template #priceInput>
          <input [(ngModel)]="priceModel" #crtl="ngModel" type="text" #editInputRef class="price-input" (blur)="toggleEditPrice(element)" [value]="priceModel">
        </ng-template>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="['priceName', 'unitPrice', 'customerPrice']"></mat-header-row>
    <mat-row [ngClass]="{'uber': row.title === 'Uber Store'}" *matRowDef="let row; columns: ['priceName', 'unitPrice','customerPrice']" [ngStyle]="{'display': row.price !== null ? 'flex' : 'none'}"></mat-row>
  </mat-table>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="accent" class="mm-text-white" (click)="showConfirm = true" *ngIf="showConfirm === false else confirm">Save</button>
  <ng-template #confirm>
    <button mat-flat-button class="mm-bg-green mm-text-white" [mat-dialog-close]="data">Are you sure?</button>
  </ng-template>
</div> -->
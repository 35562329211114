import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface QuestionsRequest {
  items: string;
  split_questions: number;
}
export interface AnswerOption {
  option_choice_name: string;
  option_type: string;
  next_questions: string | null;
  option_text: string | null;
  can_proceed: number;
}
export interface Question {
  question: string;
  question_id: number;
  question_type: string;
  answer_required: number;
  allow_multiple_options: number;
  conditional: number;
  subtext: string | null;
  medmate_item_code: string;
  answer_options: AnswerOption[] | null
}
export interface QuestionsResponse {
  questions: Question[]
  conditional_questions?: {
    [key:number]: Question
  }
}
@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  constructor(private http: HttpClient) { }

  get_questions_by_items(request: QuestionsRequest) {
    const params = new HttpParams()
    .set('items', request.items)
    .set('split_questions', request.split_questions)

    return this.http.get<QuestionsResponse>(config.apiUrl1 + 'get_questions_by_items', { params })
    .pipe(
      catchError(err => throwError(err))
    )
  }
}

import { config } from "config";

export const environment = {
  production: true,
  apiURL: config.apiUrl,
  apiURL1: config.apiUrl1,
  firebaseConfig: {
    apiKey: "AIzaSyD1qpRL3iO5ArA6_Iwme9-5-yZBkCOArHA",
    authDomain: "medmate-5a078.firebaseapp.com",
    databaseURL: "https://medmate-5a078.firebaseio.com",
    projectId: "medmate-5a078",
    storageBucket: "medmate-5a078.appspot.com",
    messagingSenderId: "617939792199",
    appId: "1:617939792199:web:2dab9ab0799f6212c21c62",
    measurementId: "G-L7DKW5B4B7"
  }
};
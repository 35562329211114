<div class="incentive-card" 
  [ngClass]="{
    'completed': !isResource && completed(),
    'completeable': completeable
  }">
  <div class="incentive-card_header">
    <div *ngIf="incentive.showProgress">
      <button matTooltip="Mark Complete" [disabled]="!completeable" (click)="markComplete()" class="incentive-card__trigger">
        <mat-icon *ngIf="completed(); else unchecked" color="accent">check_circle</mat-icon>
        <ng-template #unchecked>
          <mat-icon>radio_button_unchecked</mat-icon>
        </ng-template>
      </button>
      <div class="incentive-card_progress">{{ incentive.progress + '/' + incentive.maxProgress }}</div>
    </div>
    <div class="flex-1" [ngClass]="{'incentive-card__content-hold': !isResource && completed()}">
      <div class="incentive-card_title">{{ incentive.title }}</div>
      <div *ngIf="incentive.content" class="incentive-card_content">{{ incentive.content }}</div>
    </div>
    <div *ngIf="incentive.type === 'File'" style="flex-basis: 75px;">
      <input type="file" id="file" (change)="handleFileChange($event)" class="d-none" accept="image/*">
      <label for="file" class="incentive-card_action">
        <div>
          <div>Upload</div>
          <mat-icon color="accent">attachment</mat-icon>
        </div>
      </label>
    </div>
    <div *ngIf="incentive.type === 'External Link'; else internalMedia" class="mm-text-center mm-link mm-text-accent"
      style="flex-basis: 75px;">
      <a target="_blank" [href]="incentive.actionMeta">{{ incentive.action }}</a>
    </div>
    <ng-template #internalMedia>
      <div *ngIf="incentive.type === 'Internal Media' else internalLink" class="mm-text-center mm-link mm-text-accent"
        style="flex-basis: 75px;">
        <div (click)="openVideo()" class="mm-link">{{ incentive.action }}</div>
      </div>
    </ng-template>
    <ng-template #internalLink>
      <div *ngIf="incentive.type === 'Internal Link' else other" class="mm-text-center mm-link mm-text-accent"
        style="flex-basis: 75px;">
        <a [href]="incentive.actionMeta" (click)="$event.preventDefault(); toInternalLink()" class="mm-link">{{
          incentive.action }}</a>
      </div>
    </ng-template>
    <ng-template #other>
      <div *ngIf="incentive.action && incentive.type !== 'External Link'" class="mm-link mm-text-accent mm-text-center"
        style="flex-basis: 75px;">
        <div (click)="markComplete()">{{ incentive.action }}</div>
      </div>
    </ng-template>
  </div>
</div>
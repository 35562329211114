import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, groupBy } from 'rxjs/operators';
import { JwtAuthService } from '../auth/jwt-auth.service';
import { config } from 'config'
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private jwtAuth: JwtAuthService) { }

  getActiveProducts(pageSize?, pageIndex?): Observable<any> {
    const url = `${config.apiUrl}productsByLocations`
    const data = {
      "locationId": this.jwtAuth.getUser().locationid,
      "status": 1
    }
    if(pageSize && pageIndex){
      data["perPage"] = pageSize,
      data["page"] = pageIndex
    }

    return this.http.post(url, data)
  }

  getInactiveProducts(pageSize?, pageIndex?): Observable<any> {
    const url = `${config.apiUrl}productsByLocations`
    const data = {
      "locationId": this.jwtAuth.getUser().locationid,
      "status": 0
    }
    if(pageSize && pageIndex){
      data['perPage'] = pageSize;
      data['page'] = pageIndex;
    }

    return this.http.post(url, data)
  }

  getPharmacyProductsForAdmin(userId, status, pageSize?, pageIndex?): Observable<any> {
    const url = `${config.apiUrl}productsByLocations`
    const data = {
      "locationId": userId,
      "status": status
    }
    if(pageSize && pageIndex){
      data['perPage'] = pageSize;
      data['page'] = pageIndex;
    }
    return this.http.post(url, data)
  }

  /**
   * Gets the products form the pharmacy's catalogue based on item codes
   *
   * @param   {number}  userId     The location id of the store
   * @param   {number}  status     The status of the store
   * @param   {string}  itemCodes  The item codes for each item - comma separated
   *
   * @return  {Observable<any>}             [return description]
   */
  getSpecificCatalogueProducts(userId, status, itemCodes): Observable<any> {
    const url = `${config.apiUrl}productsByLocations`
    return this.http.post(url, {
      'locationId': userId,
      'status': status,
      'MedmateItemCode': itemCodes
    })
  }

  getProductsByData(data): Observable<any> {
    const url = `${config.apiUrl}productsByLocations`
    return this.http.post(url, data)
  }

  
  downloadCatalogue(data): Observable<any> {
    const url = `${config.apiUrl}downloadCatalogue`
    return this.http.post(url, data).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }

  uploadCatalogue(data): Observable<any> {
    const url = `${config.apiUrl}updateCatalogue`
    return this.http.post(url, data).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }

  activateProduct(data): Observable<any> {
    const url = `${config.apiUrl}activateProduct`
    return this.http.post(url, data).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }

  setProductsForPharmacy(data): Observable<any> {
    const url = `${config.apiUrl}setProductsByLocations`
    return this.http.post(url, data).pipe(
      catchError(err => {
        return throwError(err)
      })
    )
  }
}

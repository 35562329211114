import { NgModule, ErrorHandler, InjectionToken, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store'
import { pharmacyCatalogueReducer } from 'app/shared/redux/pharmacy-catalogue/catalogue-item.reducer'
// import { GestureConfig } from '@angular/material/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete'

//firebase configuration
import { environment } from 'environments/environment';
import { AngularFireModule } from '@angular/fire/compat';

import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { AuthComponent } from './shared/services/auth/auth.component';
import { ComingSoonComponent } from './views/coming-soon/coming-soon/coming-soon.component';
import { CustomersComponent } from './views/users/invite/customers.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import  {MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectSearchModule } from 'mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { AccessDeniedComponent } from './views/access-denied/access-denied.component';
import { SupportComponent } from './views/support/support.component';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { PortalSettingsService } from './shared/services/portal-settings.service';
import { QuestionsComponent } from './shared/components/questions/questions.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableModule } from './shared/components/table/table.module';
import { ProgramsModule } from './views/programs/programs.module';
import { ProgramComponent } from './views/programs/program/program.component';
import { IncentiveCardComponent } from './views/programs/incentive-card/incentive-card.component';
import { BlobComponent } from './shared/components/blob/blob.component';
import { MatTooltipModule } from '@angular/material/tooltip';




// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


function initializeAppFactory(portalSettingsService: PortalSettingsService) {
  return () => portalSettingsService.getPortalSettings()
}

@NgModule({
  imports: [
    MatTableModule,
    MatSortModule,
    MatTableExporterModule,
    MatTabsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'medmate'),
    PerfectScrollbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatDividerModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectSearchModule,
    MatSelectModule,
    FormsModule, 
    MatTooltipModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true}),
    RouterModule.forRoot(rootRouterConfig, { useHash: false, relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot({pharmacyCatalogue: pharmacyCatalogueReducer}),
    GooglePlaceModule,
  ],
  declarations: [AppComponent, ComingSoonComponent, AccessDeniedComponent, SupportComponent, QuestionsComponent, ProgramComponent, IncentiveCardComponent, BlobComponent],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeAppFactory, deps: [PortalSettingsService], multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    // REQUIRED IF YOU USE JWT AUTHENTICATION
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
<div class="header">
    <h2>Upload CSV</h2>
    <mat-icon (click)="close()">close</mat-icon>
</div>
<div class="upload-container" [ngClass]="{'drag': dragOver == true}" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
    <label for="file-upload">
        <img src="../../../../assets/images/svg-icons/add-file.svg">
        Select a CSV file to upload
        <span>or drag and drop it here</span>
    </label>
    <div *ngIf="file" class="file-container">
        <mat-icon>check_circle_outline</mat-icon>
        <span>{{file.name}}</span>
    </div>
</div>
<input type="file" accept='.csv' style='opacity: 0' id="file-upload" name="file-upload" (change)="uploadFile($event)">
<div class="pma-container" *ngIf="admin && !uploading && file">
    <label for="pma">Please enter a Platform Markup Amount for this catalogue.</label>
    <span class="input-symbol-dollar">
        <input type='number' class="mm-text-input" [formControl]="PMA" name="pma">
    </span>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="uploading"></mat-progress-bar>
<mat-dialog-actions align="end">
    <button mat-flat-button class="mm-bg-primary mm-text-white mm-mt-10" [disabled]='uploading' (click)="onConfirm()">Confirm</button>
</mat-dialog-actions>
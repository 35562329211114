import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { User } from '../models/user.model';
import { JwtAuthService } from './auth/jwt-auth.service';
// import{HttpParams} from '@an'
// export interface OrderCount {
//   deliveryPickCount: string;
//   pendingApprovalCount: string;
//   priceRequestCount: string;
//   processCount: string;
//   totalcount: string;
//   activeCount?:string;
//   exceptionCount?:string;
//   closeCount?:string;
//   pharmacytoreviewCount?:string;
//   medmatetoreviewCount?:string;
//   pendingscriptsCount?:string;
//   scriptsissuedCount?:string;

// }
export interface OrderCount {
  online: online_counts
  in_store: in_store_counts
  all_orders: all_orders_counts
  closed: closed_counts
}
export interface online_counts {
  pending_approval: 0;
  clinic_to_review: 0;
  clinic_to_action: 0,
  price_request: 0,
  processing: 0,
  delivery_pickup: 0
}

export interface in_store_counts {
  pending_approval: 0,
  clinic_to_review: 0,
  clinic_to_action: 0,
  price_request: 0,
  processing: 0,
  delivery_pickup: 0
}

export interface all_orders_counts {
  clinic_to_review: 0,
  clinic_to_action: 0,
  request_issued: 0,
  pending_approval: 0,
  price_request: 0,
  processing: 0,
  delivery_pickup: 0,
  active: 0,
  exception: 0
}

export interface closed_counts {
  completed: 0,
  cancelled: 0
}

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  value?: string;
  color1?: string;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
  value?: string;
  color1?: string;
  id?: string
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  adminMenu: IMenuItem[] = [
    {
      name: 'Create New Order',
      type: 'link',
      icon: 'description',
      state: 'orders/create-order'
    },
    {
      name: 'Online',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: "warn",
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
        { name: 'Price Request', state: 'price_request', value: '', color1: "#1e98d5" },
        { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
      ]
    },
    {
      name: 'In Store',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/in_store',
      value: '',
      color1: "warn",
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
        { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
      ]
    },
    {
      name: 'All Orders',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/admin',
      value: '',
      color1: "warn",
      sub: [
        { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
        { name: 'Request Issued', state: 'request_issued', value: '', color1: "#1e98d5" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
        { name: 'Price Request', state: 'price_request', value: '', color1: "#1e98d5" },
        { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
        { name: 'Active', state: 'active', value: '', color1: "#ff3d57" },
        { name: 'Exception', state: 'exception', value: '', color1: "pink" },
        { name: 'Repeat Order', state: 'repeat_order', value: '', color1: "#ff7901" },
      ]
    },
    {
      name: 'Closed',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: "warn",
      sub: [
        { name: 'Completed', state: 'completed', value: '', color1: "grey" },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: "grey" },
      ]
    },
    {
      name: "Users",
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon 
      svgIcon: 'ulb_server',
      state: 'users',
      value: '',
      sub: [
        {
          name: 'Invite',
          type: 'link',
          icon: 'person_add',
          state: 'invite'
        },
        {
          name: 'Create User',
          type: 'link',
          icon: 'person_add',
          state: 'create-user'
        },

      ]
    },
    {
      name: "Resources",
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon 
      svgIcon: 'ulb_server',
      state: 'resources',
      value: '',
      sub: [
        {
          name: 'On Boarding',
          type: 'link',
          icon: 'ulb_server',
          state: 'on-boarding'
        },
        {
          name: 'Setup',
          type: 'link',
          icon: 'ulb_server',
          state: 'setup'
        },
        {
          name: 'Training',
          type: 'link',
          icon: 'ulb_server',
          state: 'training-guide'
        },
        {
          name: 'Program',
          type: 'link',
          icon: 'person_add',
          state: 'pharmacy-program'
        }
      ]
    },
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'description',
      state: 'reports',
      sub: [
        { name: 'Location', state: 'locations' },
        { name: 'Partners', state: 'partners' },
        { name: 'Telehealth', state: 'telehealth' },
        { name: 'Programs', state: 'programs' },
      ]
    },
    {
      name: 'Stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [
        { name: 'New Store', state: 'create-store' },
        { name: 'Store Details', state: 'store-detail' },
        // { name: 'Delivery Options', state: 'analytics' },
        // { name: 'Delivery Partners', state: 'deliverypartners' },
      ]
    },
    {
      name: 'Products',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'products',
      sub: [
        { name: 'Active', state: 'active' },
        { name: 'Inactive', state: 'inactive' },
        // { name: 'New Product', state: 'default' }
      ]
    },
    {
      name: 'Rendr',
      type: 'link',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_rendr',
      state: 'orders/rendr'
    },
    {
      name: 'Billing',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'dashboard',
      sub: [
        { name: 'Statements', state: 'default' },

      ]
    },
    {
      name: 'Admin',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'admin',
      sub: [
        { name: 'Known As', state: 'default' },
        { name: 'Notification Monitor', state: 'monitor'},
        { name: 'Restricted Meds', state: 'restricted-meds'},
        // { name: 'Programs', state: 'programs'},
      ]
    }
  ];

  portalUserMenu: IMenuItem[] = [
    {
      name: 'Create New Order',
      type: 'link',
      icon: 'description',
      state: 'orders/create-order'
    },
    {
      name: 'Online',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: "warn",
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
        { name: 'Price Request', state: 'price_request', value: '', color1: "#1e98d5" },
        { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
      ]
    },
    // {
    //   name: 'In Store',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   svgIcon: 'ulb_server',
    //   state: 'orders/in_store',
    //   value: '',
    //   color1: "warn",
    //   sub: [
    //     // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
    //     { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
    //     { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
    //     { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
    //     { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
    //   ]
    // },
    {
      name: 'Closed',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: "warn",
      sub: [
        { name: 'Completed', state: 'completed', value: '', color1: "grey" },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: "grey" },
      ]
    },
    {
      name: "Resources",
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon 
      svgIcon: 'ulb_server',
      state: 'resources',
      value: '',
      sub: [
        {
          name: 'On Boarding',
          type: 'link',
          icon: 'ulb_server',
          state: 'on-boarding'
        },
        {
          name: 'Setup',
          type: 'link',
          icon: 'ulb_server',
          state: 'setup'
        },
        {
          name: 'Training',
          type: 'link',
          icon: 'ulb_server',
          state: 'training-guide'
        },
        {
          name: 'Program',
          type: 'link',
          icon: 'person_add',
          state: 'pharmacy-program'
        }
      ]
    },
    // {
    //   name: "Users",
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   //TODO: change icon 
    //   svgIcon: 'ulb_server',
    //   state: 'users',
    //   value: '',
    //   sub: [
    //     {
    //       name: 'Invite',
    //       type: 'link',
    //       icon: 'person_add',
    //       state: 'invite'
    //     }
    //   ]
    // },
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'description',
      state: 'reports',
      sub: [
        { name: 'Location', state: 'locations' },
      ]
    },
    {
      name: 'Stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [
        { name: 'Store Details', state: 'store-detail' },
      ]
    },
    {
      name: 'Products',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'products',
      sub: [
        { name: 'Active', state: 'active' },
        { name: 'Inactive', state: 'inactive' },
        // { name: 'New Product', state: 'default' }
      ]
    },
  ];

  businessUserMenu: IMenuItem[] = [
    {
      name: 'Create New Order',
      type: 'link',
      icon: 'description',
      state: 'orders/create-order'
    },
    {
      name: 'Online',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: "warn",
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
        { name: 'Price Request', state: 'price_request', value: '', color1: "#1e98d5" },
        { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
      ]
    },
    // {
    //   name: 'In Store',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   svgIcon: 'ulb_server',
    //   state: 'orders/in_store',
    //   value: '',
    //   color1: "warn",
    //   sub: [
    //     // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
    //     { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
    //     { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
    //     { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
    //     { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
    //   ]
    // },
    {
      name: 'Closed',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: "warn",
      sub: [
        { name: 'Completed', state: 'completed', value: '', color1: "grey" },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: "grey" },
      ]
    },
    {
      name: "Users",
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon 
      svgIcon: 'ulb_server',
      state: 'users',
      value: '',
      sub: [
        {
          name: 'Invite',
          type: 'link',
          icon: 'person_add',
          state: 'invite'
        }
      ]
    },
    {
      name: "Resources",
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon 
      svgIcon: 'ulb_server',
      state: 'resources',
      value: '',
      sub: [
        {
          name: 'On Boarding',
          type: 'link',
          icon: 'ulb_server',
          state: 'on-boarding'
        },
        {
          name: 'Setup',
          type: 'link',
          icon: 'ulb_server',
          state: 'setup'
        },
        {
          name: 'Training',
          type: 'link',
          icon: 'ulb_server',
          state: 'training-guide'
        },
        {
          name: 'Program',
          type: 'link',
          icon: 'person_add',
          state: 'pharmacy-program'
        }
      ]
    },
    // {
    //   name: "Users",
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   //TODO: change icon 
    //   svgIcon: 'ulb_server',
    //   state: 'users',
    //   value: '',
    //   sub: [
    //     {
    //       name: 'Invite',
    //       type: 'link',
    //       icon: 'person_add',
    //       state: 'invite'
    //     }
    //   ]
    // },
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'description',
      state: 'reports',
      sub: [
        { name: 'Location', state: 'locations' },
        { name: 'Programs', state: 'programs' },
      ]
    },
    {
      name: 'Stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [
        { name: 'New Store', state: 'create-store' },
        { name: 'Store Details', state: 'store-detail' },
      ]
    },
    {
      name: 'Products',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'products',
      sub: [
        { name: 'Active', state: 'active' },
        { name: 'Inactive', state: 'inactive' },
        // { name: 'New Product', state: 'default' }
      ]
    },
  ];

  userMenu: IMenuItem[] = [
    {
      name: 'Create New Order',
      type: 'link',
      icon: 'description',
      state: 'orders/create-order'
    },
    {
      name: 'Online',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/online',
      value: '',
      color1: "warn",
      sub: [
        // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
        { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
        { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
        { name: 'Price Request', state: 'price_request', value: '', color1: "#1e98d5" },
        { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
      ]
    },
    // {
    //   name: 'In Store',
    //   type: 'dropDown',
    //   tooltip: 'Dashboard',
    //   svgIcon: 'ulb_server',
    //   state: 'orders/in_store',
    //   value: '',
    //   color1: "warn",
    //   sub: [
    //     // { name: 'Clinic to Review', state: 'clinic_to_review', value: '', color1: "#72cb38" },
    //     { name: 'Pending Approval', state: 'pending_approval', value: '', color1: "#ff7901" },
    //     { name: 'Clinic to Action', state: 'clinic_to_action', value: '', color1: "#72cb38" },
    //     { name: 'Ready to Process', state: 'processing', value: '', color1: "#1e98d5" },
    //     { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
    //   ]
    // },
    {
      name: 'Closed',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders/closed',
      value: '',
      color1: "warn",
      sub: [
        { name: 'Completed', state: 'completed', value: '', color1: "grey" },
        { name: 'Cancelled', state: 'cancelled', value: '', color1: "grey" },
      ]
    },
    {
      name: 'Stores',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'stores',
      sub: [
        { name: 'Location Details', state: 'location-details' },
      ]
    },
    {
      name: 'Products',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'products',
      sub: [
        { name: 'Active', state: 'active' },
        { name: 'Inactive', state: 'inactive' },
        // { name: 'New Product', state: 'coming-soon' }
      ]
    },
    {
      name: "Users",
      type: 'dropDown',
      tooltip: 'Dashboard',
      //TODO: change icon 
      svgIcon: 'ulb_server',
      state: 'users',
      value: '',
      sub: [
        {
          name: 'Invite',
          type: 'link',
          icon: 'person_add',
          state: 'invite'
        }
      ]
    },
  ];

  driverMenu: IMenuItem[] = [
    {
      name: 'Online',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'orders',
      value: '',
      color1: "warn",
      sub: [
        { name: 'Delivery/Pickup', state: 'delivery_pickup', value: '', color1: "#1e98d5" },
      ]
    },
  ];

  //set values for newordercount then override with fetch values.
  // userMenu:IMenuItem[]=[
  //   {
  //     name: 'Orders',
  //     type: 'dropDown',
  //     tooltip: 'Dashboard',
  //     svgIcon: 'ulb_server',
  //     state: 'orders',
  //     value: '',
  //     color1: "warn",
  //     sub: [
  //       { name: 'Pending Approval', state: 'pending', value: '', color1: "#ff7901" },
  //       { name: 'Price Request', state: 'price', value: '', color1: "#72cb38" },
  //       { name: 'Processing', state: 'processing', value: '', color1: "#1e98d5" },
  //       { name: 'Delivery/Pickup', state: 'deliverypickup', value: '', color1: "#1e98d5" },
  //       { name: 'Closed', state: 'closed', value: '', color1: "primary" },
  //       { name: 'Active', state: 'allorders', value: '', color1: "#ff3d57", }
  //     ]
  //   },
  //   {
  //     name: 'Customers',
  //     type: 'link',
  //     icon: 'blur_on',
  //     state: 'others/blank'
  //   },

  //   {
  //     name: 'Stores',
  //     type: 'dropDown',
  //     tooltip: 'Dashboard',
  //     svgIcon: 'ulb_server',
  //     state: 'stores',
  //     sub: [
  //       { name: 'New Store', state: 'create' },
  //       { name: 'Location Details', state: 'learning-management' },
  //       { name: 'Delivery Options', state: 'analytics' },
  //       { name: 'Delivery Partners', state: 'deliverypartners' },
  //     ]
  //   },
  //   {
  //     name: 'Products',
  //     type: 'dropDown',
  //     tooltip: 'Dashboard',
  //     svgIcon: 'ulb_server',
  //     state: 'products',
  //     sub: [
  //       { name: 'Active', state: 'active' },
  //       { name: 'Inactive', state: 'inactive' },
  //       { name: 'New Product', state: 'default' }
  //     ]
  //   },

  //   {
  //     name: 'Billing',
  //     type: 'dropDown',
  //     tooltip: 'Dashboard',
  //     svgIcon: 'ulb_server',
  //     state: 'dashboard',
  //     sub: [
  //       { name: 'Statements', state: 'default' },

  //     ]
  //   }

  // ]
  newOrderCount: OrderCount;
  // newOrderCount:OrderCount={
  //   deliveryPickCount:0,
  //   pendingApprovalCount:0,
  //   priceRequestCount:0,
  //   processCount: 0,
  //   totalcount: 0
  // };
  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets adminMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.userMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // menuCounts= new BehaviorSubject<OrderCount>(this.newOrderCount);
  // menuCounts$= this.menuCounts.asObservable();


  constructor(private http: HttpClient, private jwtAuth: JwtAuthService) { }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuItem) {
    switch (menuItem) {
      case 'user-menu':
        this.menuItems.next(this.userMenu);
        break;
      case 'admin-menu':
        this.menuItems.next(this.adminMenu);
        break;
      case 'portal-user-menu':
        this.menuItems.next(this.portalUserMenu);
        break;
      case 'business-user-menu':
        this.menuItems.next(this.businessUserMenu);
        break;
      case 'driver-menu':
        this.menuItems.next(this.driverMenu);
        break;
      default:
        this.menuItems.next(this.userMenu);
    }
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  set_counts(menu_item, counts) {
    menu_item.sub.forEach(element => {
      element.value = counts[element.state];
    });
  }
  setOrderCount(OrdersCount: OrderCount) {
    this.newOrderCount = OrdersCount;

    let online_menu = null
    let in_store_menu = null
    let all_orders_menu = null
    let closed_menu = null

    if (this.jwtAuth.getUser().role === 'Admin') {
      online_menu = this.adminMenu.find(m => m.name === 'Online')
      in_store_menu = this.adminMenu.find(m => m.name === 'In Store')
      all_orders_menu = this.adminMenu.find(m => m.name === 'All Orders')
      closed_menu = this.adminMenu.find(m => m.name === 'Closed')
    } else if (this.jwtAuth.getUser().role === 'Portal User') {
      online_menu = this.portalUserMenu.find(m => m.name === 'Online')
      in_store_menu = this.portalUserMenu.find(m => m.name === 'In Store')
      closed_menu = this.portalUserMenu.find(m => m.name === 'Closed')
    } else if (this.jwtAuth.getUser().role === 'Business User') {
      online_menu = this.businessUserMenu.find(m => m.name === 'Online')
      in_store_menu = this.businessUserMenu.find(m => m.name === 'In Store')
      closed_menu = this.businessUserMenu.find(m => m.name === 'Closed')
    } else if (this.jwtAuth.getUser().role === 'Driver') {
      online_menu = this.driverMenu.find(m => m.name === 'Online')
      in_store_menu = this.driverMenu.find(m => m.name === 'In Store')
      closed_menu = this.driverMenu.find(m => m.name === 'Closed')
    } else {
      online_menu = this.userMenu.find(m => m.name === 'Online')
      in_store_menu = this.userMenu.find(m => m.name === 'In Store')
      closed_menu = this.userMenu.find(m => m.name === 'Closed')
    }

    if (online_menu !== null) {
      this.set_counts(online_menu, this.newOrderCount.online)
    }
    if (in_store_menu !== null) {
      this.set_counts(in_store_menu, this.newOrderCount.in_store)
    }
    if (all_orders_menu !== null) {
      this.set_counts(all_orders_menu, this.newOrderCount.all_orders)
    }
    if (closed_menu !== null) {
      this.set_counts(closed_menu, this.newOrderCount.closed)
    }
    // let total=(this.newOrderCount.pendingApprovalCount + this.newOrderCount.priceRequestCount +this.newOrderCount.processCount + this.newOrderCount.deliveryPickCount);

    // this.adminMenu[0].sub[6].value = this.newOrderCount.exceptionCount;




    // this.adminMenu.push() 
    // console.log("hello");

  }

  // setOrderCountForDirect(OrdersCount: OrderCount) {
  //   const directSubMenu = this.adminMenu.find(m => m.name === 'Direct')
  //   directSubMenu.sub[0].value = OrdersCount.pendingApprovalCount;
  //   directSubMenu.sub[1].value = OrdersCount.priceRequestCount;
  //   directSubMenu.sub[2].value = OrdersCount.processCount;
  //   directSubMenu.sub[3].value = OrdersCount.deliveryPickCount;
  //   directSubMenu.sub[4].value = OrdersCount.closeCount;
  //   directSubMenu.sub[5].value = OrdersCount.activeCount;
  //   directSubMenu.sub[6].value = OrdersCount.exceptionCount;
  //   directSubMenu.sub[6].value = OrdersCount.exceptionCount;
  // }

  // setOrderCountForTelehealth(OrdersCount: OrderCount) {
  //   const telehealthAdminSubMenu = this.adminMenu.find(m => m.name === 'Telehealth')
  //   telehealthAdminSubMenu.sub[0].value = OrdersCount.pharmacytoreviewCount;
  //   telehealthAdminSubMenu.sub[1].value = OrdersCount.medmatetoreviewCount;
  //   telehealthAdminSubMenu.sub[2].value = OrdersCount.pendingscriptsCount;
  //   telehealthAdminSubMenu.sub[3].value = OrdersCount.scriptsissuedCount;
  //   telehealthAdminSubMenu.sub[4].value = OrdersCount.closeCount;

  //   if(this.jwtAuth.getUser().role === 'User') {
  //   const telehealthUserSubMenu = this.userMenu.find(m => m.name === 'Telehealth')
  //   telehealthUserSubMenu.sub[0].value = OrdersCount.pharmacytoreviewCount;
  //   telehealthUserSubMenu.sub[1].value = OrdersCount.medmatetoreviewCount;
  //   telehealthUserSubMenu.sub[2].value = OrdersCount.pendingscriptsCount;
  //   telehealthUserSubMenu.sub[3].value = OrdersCount.scriptsissuedCount;
  //   telehealthUserSubMenu.sub[4].value = OrdersCount.closeCount;

  // }

  //   if(this.jwtAuth.getUser().role === 'Portal User') {
  //     const telehealthPortalUserSubMenu = this.portalUserMenu.find(m => m.name === 'Telehealth')
  //     telehealthPortalUserSubMenu.sub[0].value = OrdersCount.pharmacytoreviewCount;
  //     telehealthPortalUserSubMenu.sub[1].value = OrdersCount.medmatetoreviewCount;
  //     telehealthPortalUserSubMenu.sub[2].value = OrdersCount.pendingscriptsCount;
  //     telehealthPortalUserSubMenu.sub[3].value = OrdersCount.scriptsissuedCount;
  //     telehealthPortalUserSubMenu.sub[4].value = OrdersCount.closeCount;
  //   }
  // }

  getOrderCountForMenu() {
    let searchParams = new HttpParams();
    if (this.jwtAuth.getUser().role === 'Admin') {
      searchParams = searchParams.append("type", 'online,in_store,all_orders,closed');
    } else if (this.jwtAuth.getUser().role === 'Portal User') {
      searchParams = searchParams.append("type", 'online,in_store,closed');
    } else if (this.jwtAuth.getUser().role === 'Business User') {
      searchParams = searchParams.append("type", 'online,in_store,closed');
    } else if (this.jwtAuth.getUser().role === 'Driver') {
      return
    } else if(this.jwtAuth.getUser().role === 'User') {
      searchParams = searchParams.append("type", 'online,in_store,closed');
    }
    this.http.get<OrderCount>(environment.apiURL1 + 'get_order_count_for_menu_v1', { params: searchParams }).subscribe(res => {
      console.warn('makeeee')
      this.setOrderCount(res);
    })
  }

  // getOrderCountForDirectMenu() {
  //   let searchParams = new HttpParams();
  //   searchParams = searchParams.append("businessType", 'Medmate Direct');
  //   this.http.get<OrderCount>(environment.apiURL + 'getOrderCountForMenu', { params: searchParams }).subscribe(res => {
  //     console.warn('makeeee')
  //     this.setOrderCountForDirect(res);
  //   })
  // }

  // getOrderCountForTelehealthMenu() {
  //   let searchParams = new HttpParams();
  //   searchParams = searchParams.append("businessType", 'Medmate Telehealth');
  //   this.http.get<OrderCount>(environment.apiURL + 'getOrderCountForMenu', { params: searchParams }).subscribe(res => {
  //     console.warn('makeeee')
  //     this.setOrderCountForTelehealth(res);
  //   })
  // }

}

import { Component, ElementRef, OnInit, ViewChild, Inject, ViewChildren, Input, QueryList} from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'app/shared/services/products/products.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { formatDate } from '@angular/common'
import { Pharmacy } from 'app/shared/models/pharmacy.model';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { EditPriceService } from 'app/shared/services/products/edit-price.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {JwtAuthService} from 'app/shared/services/auth/jwt-auth.service';
import { ListPharmaciesService } from 'app/shared/services/products/list-pharmacies.service';
import { fromEvent, iif, Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith, takeWhile, tap } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CatalogueItem } from 'app/shared/models/catalogueItem.model';
import { addPharmacyCatalogueItems } from 'app/shared/redux/pharmacy-catalogue/catalogue-items.actions';
import { selectPharmacyCatalogue } from 'app/shared/redux/pharmacy-catalogue/catalogue-item.selector';
import { NavigationService } from 'app/shared/services/navigation.service';
import { MatDrawer } from '@angular/material/sidenav';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { ActivePharmacy } from 'app/shared/models/activePharmacy.model';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { UberService } from 'app/shared/services/uber/uber.service';
import { MatSelectChange } from '@angular/material/select';
import { PRODUCT_CATEGORY_MAP } from 'app/shared/helpers/product-category-map.helper';

@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.scss']
})
export class ActiveComponent implements OnInit {
  displayedColumns: string[] = ['image', 'productName', 'price',  'available', 'inStock', 'nextInStock'];
  categories = [];
  activeFilter = false
  editPrice: boolean = false;
  loading: boolean = false;
  searching: boolean = false;
  search = new FormControl('');
  categorySelect = new FormControl();
  categoryMap = PRODUCT_CATEGORY_MAP;
  @ViewChild('searchbar') searchbar: ElementRef;
  obs:Subscription;
  priceModel: string = '';
  dataSource
  @ViewChild(MatTable) table!: MatTable<any>;
  dateNow: Date = new Date()
  @Input('inactive') inactive:boolean = false
  @ViewChildren('ean') eanP: ElementRef;
  @ViewChild('editStockRef') editStockRef: ElementRef;
  numDays: number[] = Array.from({length: 20}, (_, i) => i + 1)
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatDrawer) drawer: MatDrawer
  @ViewChild('imageMenuTrigger') imageMenuTrigger: MatMenuTrigger;
  @ViewChildren('available') sellItemSlideToggle: QueryList<MatSlideToggle>;
  chosenPharmacy: ActivePharmacy = {
    locationname: '',
    uberid: '',
  }
  noData: boolean = false
  chosenFilters = []
  filteredFilters: Observable<string[]>
  filters: string[] = ['Uber']
  filterFormControl = new FormControl()
  pharmacies
  origFilterPredicate
  drawerContent = { //initialise drawerContent
    PharmacyId: '',
    Businessid: '',
    locationId: '',
    pricingTier: '',
    MedmateItemCode: '',
    PhotoID_s3: '',
    PhotoID: '',
    image: '',
    EAN: '',
    ItemName: '',
    Cat_Level1: '',
    Cat_Level2: '',
    standardPrice: '',
    salePrice: '',
    thirdPartyPrice: '',
    Availability: 0,
    stockOnHand: 0,
    stockAvailabilityDate: '',
    Status: 0,
    RRP: '',
    PlatformMarkupAmount: '',
    PlatformMarkupPercentage: '',
    UberEatsPrice: '',
    BasketFiller: '',
    viewOrder: '',
    TopSellerOrder: '',
    overlay: '',
    editStock: false,
    originalStockQty: 0,
  }
  priceTooltip = 'Prices you confirm here are the product fees that you wish to receive for the item less service, administration and delivery fees as outlined in the Medmate Commercials.'
  flexAlign: string = ''
  categories1 = []
  
  // Disable inputs if MASTER/BUSINESS CATALOGUE
  locationDetails;
  disableRow: boolean = false;

  // Custom Pagination
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 50;
  from: number = 0;
  to: number = 0;
  firstPage: number = 1;
  currentPage: number;
  lastPage: number = 1;
  length: number = 0;
  pageOffset: number;
  
  // More Actions
  showMoreActions: boolean = false;
  menuItems = [
    {
      name: 'Upload',
      color: 'black',
      sub: []
    },
    {
      name: 'Download',
      color: 'black',
      sub: [
        'Full Catalogue', 
        'Top 500 Items',
      ] 
    },
  ]
  
  constructor(
    private products:ProductsService, 
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar,
    private jwtAuth: JwtAuthService,
    private listPharmaciesService: ListPharmaciesService,
    private ls: LocalStoreService,
    private uber: UberService,
    private router: Router
  ) {}
  
  ngAfterViewInit() {
    
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    if (this.jwtAuth.getUser().role === 'Admin' || this.jwtAuth.getUser().role === 'Business User') {
      this.flexAlign = "space-between center"
      this.listPharmaciesService.getPharmacies().subscribe(res => {
        this.pharmacies = res
        if(this.ls.getItem('currentPharmacyCatalogue')) {
          this.loading = true
          const currentPharmacyCatalogue = JSON.parse( this.ls.getItem( 'currentPharmacyCatalogue' ) )
          // console.log("current pharmacy", currentPharmacyCatalogue)
          if(currentPharmacyCatalogue.OTCCatalogueBusinessID==0 && currentPharmacyCatalogue.OTCCatalogueLocationID==0){
            this.disableRow = true;
          }else if(currentPharmacyCatalogue.OTCCatalogueBusinessID>0 && currentPharmacyCatalogue.OTCCatalogueLocationID==0){
            this.disableRow = true;
          }else{
            this.disableRow = false;
          }
          this.chosenPharmacy = currentPharmacyCatalogue
          const status = this.inactive === true ? 0 : 1
          this.getPharmacyProducts(
            this.products.getPharmacyProductsForAdmin(
              currentPharmacyCatalogue.locationid, 
              status,
              this.pageSize,
              this.pageIndex
            )
          )

        }else { 
            this.openListPharmaciesDialog(this.pharmacies)
        }         
      })
    } else if(this.jwtAuth.getUser().role === 'User' || this.jwtAuth.getUser().role === 'Portal User') {
      this.locationDetails = this.jwtAuth.getUser().location
      console.log('here', this.locationDetails)
      if(this.locationDetails.OTCCatalogueBusinessID==0 && this.locationDetails.OTCCatalogueLocationID==0){
        this.disableRow = true;
      }else if(this.locationDetails.OTCCatalogueBusinessID>0 && this.locationDetails.OTCCatalogueLocationID==0 && this.jwtAuth.getUser().role != 'Admin'){
        this.disableRow = true;
      }else{
        this.disableRow = false;
      }
      this.flexAlign = "center center"
      this.loading = true
      const productType = this.inactive === true ? this.products.getInactiveProducts() : this.products.getActiveProducts()
      if(this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(
            this.pageSize, this.pageIndex
          )
        )
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(
            this.pageSize, this.pageIndex
          )
        )
      }
      this.filteredFilters.subscribe(res => this.filters.concat(this.categories1))
    }

    //observe and debounce search input
    this.obs=this.search.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.searchProducts()
      });
  }
  
  ngOnInit(): void {
    this.loading = true
    this.dataSource = new MatTableDataSource(this.categories);
    this.filteredFilters = this.filterFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterFilters(value)),
    );
    this.origFilterPredicate = this.dataSource.filterPredicate
  }

  ngOnDestroy(){
    this.obs.unsubscribe();
  }

  private _filterFilters(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.filters.filter(option => option.toLowerCase().includes(filterValue));
  }

  isGroup(index, item): boolean {
    return item.name ? true : false;
  }

  user() {
    return this.jwtAuth.getUser()
  }

  addFilters(option, e) {
    var index = this.chosenFilters.indexOf(option)
    // console.log(e)
    if(e.checked) {
      this.chosenFilters.push(option)
      // console.log(this.chosenFilters)
      this.dataSource.filterPredicate = this.custom()
      this.dataSource.filter = this.chosenFilters
    } else {
      this.chosenFilters.splice(index, 1)
      if(this.chosenFilters.length === 0) {
        // console.log(this.dataSource)
        this.dataSource.filterPredicate = this.origFilterPredicate
        return
      }
      this.dataSource.filterPredicate = this.custom()
      this.dataSource.filter = this.chosenFilters
    }
    
    // this.dataSource.filter = 
  }

  activateThirdPartyFilter() {
    this.activeFilter = !this.activeFilter
    if(this.activeFilter) {
      this.dataSource.filterPredicate = this.thirdPartyFilter()
      this.dataSource.filter = 'uber'
    } else {
      this.dataSource.filterPredicate = this.origFilterPredicate
      this.dataSource.filter = ' '
    }
  }
  
  thirdPartyFilter() {
    const thirdPartyFilter = (data, filter) => {
      const isUberItem = ( data.thirdPartyPrice !== null ) && ( parseFloat(data.thirdPartyPrice) > 0 )
      if(this.activeFilter) {
        return filter.includes(data.Cat_Level1) || isUberItem 
      }
      return filter.includes(data.Cat_Level1) 
    }
    return thirdPartyFilter
  }

  getProducts(){
    const currentPharmacyCatalogue = JSON.parse( this.ls.getItem( 'currentPharmacyCatalogue' ) )
    this.chosenPharmacy = currentPharmacyCatalogue
    const status = this.inactive === true ? 0 : 1
    const location = this.jwtAuth.getUser().role==='Admin'? this.chosenPharmacy.locationid : this.jwtAuth.getUser().locationid;
    const data = {
      "locationId": location,
      "status": status,
      'filterUber': this.activeFilter,
      'page': this.pageIndex,
      'perPage': this.pageSize
    }
    if(this.search.value){
      data['searchTerm'] = this.search.value
    }
    if(this.categorySelect.value && this.categorySelect.value != ''){
      let categoryFilters = '';
      this.categorySelect.value.forEach(x => {
        categoryFilters += `${this.categoryMap[x]},`
      })
      data['categories']=categoryFilters.substring(0, categoryFilters.length-1)
    }
        this.getPharmacyProducts(
          this.products.getProductsByData(
            data
          )
        )
  }

  getUberProducts(){
    this.loading = true;
    this.activeFilter = !this.activeFilter
    this.getProducts()
  }

  categoryFilter() {
    this.loading = true;
    this.getProducts()
  }

  custom() {
    let thing = function(data, filter) {
      const isUberItem = ( data.thirdPartyPrice !== null ) && ( parseFloat(data.thirdPartyPrice) > 0 )
    }
    return thing
  } 


  searchProducts() {
    this.searching = true;
    this.getProducts();
  }

  menuItemAction(action) {
    switch (action) {
      case 'Upload':
        const dialogRef = this.dialog.open(UploadCatalogueDialog, {
          minWidth: '350px',
          width: '25%',
          maxWidth: '800px',
          data: {
            chosenPharmacy: this.chosenPharmacy,
            admin: this.jwtAuth.getUser().role == 'Admin'? true: false,
          },
        disableClose: true,
        });

        dialogRef.afterClosed().subscribe(res=> {
          if(res.code==1){
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mat-bg-green'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: res.msg,
                action: 'dismiss',
              },
              duration: 1400
            })
          }
        })
        break;
      default:
        break;
    }
  }

  subMenuAction(action) {
    
    switch (action) {
      case 'Full Catalogue':
        var data = {
          locationid: this.chosenPharmacy.locationid,
          type: '1'
        }
        this.products.downloadCatalogue(data).subscribe(res => {
          res['filename_path']? window.open(res['filename_path'], '_blank'): this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: 'Could not download product catalogue. Please try again later.',
              action: 'dismiss',
            },
            duration: 1400
          })
        }, err => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: err,
              action: 'dismiss',
            },
            duration: 1400
        })})
        break; 
      case 'Top 500 Items':
        var data = {
          locationid: this.chosenPharmacy.locationid,
          type: '2'
        }
        this.products.downloadCatalogue(data).subscribe(res => {
          res['filename_path']? window.open(res['filename_path'], '_blank'): this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: 'Could not download product catalogue. Please try again later.',
              action: 'dismiss',
            },
            duration: 1400
          })
        }, err => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: err,
              action: 'dismiss',
            },
            duration: 1400
        })})
        break;
      default:
        break;
    }
  }

  // Custom Pagination Methods
  pageSizeChange(e: MatSelectChange) {
    this.pageSize = e.value
    if(this.jwtAuth.getUser().role === 'Admin'){
      if(this.ls.getItem('currentPharmacyCatalogue')) {
        this.loading = true
        const currentPharmacyCatalogue = JSON.parse( this.ls.getItem( 'currentPharmacyCatalogue' ) )
        this.chosenPharmacy = currentPharmacyCatalogue
        const status = this.inactive? 0: 1;
        
          this.getPharmacyProducts(
            this.products.getPharmacyProductsForAdmin(
              currentPharmacyCatalogue.locationid, 
              status,
              this.pageSize,
              this.pageIndex
            )
          )
        
      }else{
        this.openListPharmaciesDialog(this.pharmacies)
      } 
      // not admin
    }else{
      if(this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(e.value, this.pageIndex)
        )
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(e.value, this.pageIndex)
        )
      }  
    }
  }

  getFirstPage() {
    this.pageIndex = 1
    if(this.jwtAuth.getUser().role === 'Admin'){
      if(this.ls.getItem('currentPharmacyCatalogue')) {
        this.loading = true
        const currentPharmacyCatalogue = JSON.parse( this.ls.getItem( 'currentPharmacyCatalogue' ) )
        this.chosenPharmacy = currentPharmacyCatalogue
        const status = this.inactive? 0: 1;
        this.getPharmacyProducts(
          this.products.getPharmacyProductsForAdmin(
            currentPharmacyCatalogue.locationid, 
            status,
            this.pageSize,
            this.pageIndex
          )
        )
      }else{
        this.openListPharmaciesDialog(this.pharmacies)
      } 
      // not admin
    }else{
      if(this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(this.pageSize, this.pageIndex)
        )
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(this.pageSize, this.pageIndex)
        )
      }  
    }
  }

  getLastPage() {
    this.pageIndex = this.lastPage
    if(this.jwtAuth.getUser().role === 'Admin'){
      if(this.ls.getItem('currentPharmacyCatalogue')) {
        this.loading = true
        const currentPharmacyCatalogue = JSON.parse( this.ls.getItem( 'currentPharmacyCatalogue' ) )
        this.chosenPharmacy = currentPharmacyCatalogue
        const status = this.inactive? 0: 1;
        this.getPharmacyProducts(
          this.products.getPharmacyProductsForAdmin(
            currentPharmacyCatalogue.locationid, 
            status,
            this.pageSize,
            this.pageIndex
          )
        )
        // if(!this.inactive){
        //   this.getPharmacyProducts(
        //     this.products.getPharmacyProductsForAdmin(
        //       currentPharmacyCatalogue.locationid, 
        //       status
        //     )
        //   )
        // }else{
        //   this.getPharmacyProducts(
        //     this.products.getPharmacyProductsForAdmin(
        //       currentPharmacyCatalogue.locationid, 
        //       status,
        //       this.pageSize,
        //       this.pageIndex
        //     )
        //   )
        // }
      }else{
        this.openListPharmaciesDialog(this.pharmacies)
      } 
      // not admin
    }else{
      if(this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(this.pageSize, this.pageIndex)
        )
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(this.pageSize, this.pageIndex)
        )
      }  
    }
  }

  getNextPage() {
    this.pageIndex++
    if(this.jwtAuth.getUser().role === 'Admin'){
      if(this.ls.getItem('currentPharmacyCatalogue')) {
        this.loading = true
        const currentPharmacyCatalogue = JSON.parse( this.ls.getItem( 'currentPharmacyCatalogue' ) )
        this.chosenPharmacy = currentPharmacyCatalogue
        const status = this.inactive? 0: 1;
        this.getPharmacyProducts(
          this.products.getPharmacyProductsForAdmin(
            currentPharmacyCatalogue.locationid, 
            status,
            this.pageSize,
            this.pageIndex
          )
        )
        // if(!this.inactive){
        //   this.getPharmacyProducts(
        //     this.products.getPharmacyProductsForAdmin(
        //       currentPharmacyCatalogue.locationid, 
        //       status
        //     )
        //   )
        // }else{
        //   this.getPharmacyProducts(
        //     this.products.getPharmacyProductsForAdmin(
        //       currentPharmacyCatalogue.locationid, 
        //       status,
        //       this.pageSize,
        //       this.pageIndex
        //     )
        //   )
        // }
      }else{
        this.openListPharmaciesDialog(this.pharmacies)
      } 
      // not admin
    }else{
      if(this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(this.pageSize, this.pageIndex)
        )
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(this.pageSize, this.pageIndex)
        )
      }  
    }
  }

  getPreviousPage() {
    this.pageIndex--
    if(this.jwtAuth.getUser().role === 'Admin'){
      if(this.ls.getItem('currentPharmacyCatalogue')) {
        this.loading = true
        const currentPharmacyCatalogue = JSON.parse( this.ls.getItem( 'currentPharmacyCatalogue' ) )
        this.chosenPharmacy = currentPharmacyCatalogue
        const status = this.inactive? 0: 1;
        this.getPharmacyProducts(
          this.products.getPharmacyProductsForAdmin(
            currentPharmacyCatalogue.locationid, 
            status,
            this.pageSize,
            this.pageIndex
          )
        )
        // if(!this.inactive){
        //   this.getPharmacyProducts(
        //     this.products.getPharmacyProductsForAdmin(
        //       currentPharmacyCatalogue.locationid, 
        //       status
        //     )
        //   )
        // }else{
        //   this.getPharmacyProducts(
        //     this.products.getPharmacyProductsForAdmin(
        //       currentPharmacyCatalogue.locationid, 
        //       status,
        //       this.pageSize,
        //       this.pageIndex
        //     )
        //   )
        // }
      }else{
        this.openListPharmaciesDialog(this.pharmacies)
      } 
      // not admin
    }else{
      if(this.inactive) {
        this.getPharmacyProducts(
          this.products.getInactiveProducts(this.pageSize, this.pageIndex)
        )
      } else {
        this.getPharmacyProducts(
          this.products.getActiveProducts(this.pageSize, this.pageIndex)
        )
      }  
    }
  }

  openMenu(e, menu) {
    e.preventDefault()
    e.stopPropagation()
    // console.log(e)
    this.imageMenuTrigger.openMenu()
  }

  openDrawer(row) {
    this.drawerContent = row
    this.drawerContent.editStock = false
    this.drawerContent.stockOnHand = this.drawerContent.stockOnHand ? this.drawerContent.stockOnHand : 0
    this.drawerContent.originalStockQty = this.drawerContent.stockOnHand ? this.drawerContent.stockOnHand : 0 
    // console.log(this.drawerContent)
    this.drawer.open()
  }

  openDialog(e, row): void {
    e.stopPropagation()
    if(row.Status === 0){
      return;
    }
    const dialogRef = this.dialog.open(EditPriceDialog, {
      width: '30%',
      data: {...row},
      disableClose: true,
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res=='cancel'){
        return
      }else{
        this.loading = true
        this.products.activateProduct(res).subscribe(res => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: {
            msg: res.msg,
            action: 'dismiss'
          },
          duration: 1400
        })
        this.loading=false;
      }, (err) => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: {
            msg: err.msg,
            action: 'dismiss',
          },
          duration: 1400
        })
        this.loading=false;
      })
      }
    })
  }

  getFlexAlign() {
    console.log('role', this.jwtAuth.getUser().role)
    switch (this.jwtAuth.getUser().role) {
      case 'Admin':
        return 'space-between center'
      case 'User':
        return 'center center'
      default:
        break;
    }
  }

  isAdmin() {
    return this.jwtAuth.getUser().role === 'Admin'
  }

  isBusiness() {
    return this.jwtAuth.getUser().role === 'Business User'
  }

  stopPropagation(e: Event) {
    e.stopPropagation()
  }

  isPharmacySellingItem(checked: boolean, row, index) {
    let item = this.dataSource.data.find(item => item.MedmateItemCode === row.MedmateItemCode) 
    item.Status = checked ? 1 : 0
    const addOrRemove = this.inactive ? 'add' : 'remove'
    //all inactive items to pop up confirmation modal when activating
    if(checked == true){
      // console.log(row)
      const dialogRef = this.dialog.open(ActivateItemDialog, {
        minWidth: '350px',
        width: '25%',
        maxWidth: '800px',
        data: {
          ...row,
          chosenPharmacy: this.chosenPharmacy,
        },
        disableClose: true,
      })

      dialogRef.afterClosed().subscribe(res => {
        if(res=='cancel'){
          item.Status = 0;
        }else{
          this.loading = true
          this.products.activateProduct(res).subscribe(res => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: res.msg,
              action: 'dismiss'
            },
            duration: 1400
          })
          this._snackBar._openedSnackBarRef.afterDismissed().subscribe(res => {
            item.Status = checked ? 1 : 0
          })
          this.loading = false
          this.loading = true
          item.Status = 1
          const elementIndex = this.dataSource.data.indexOf(row, 0);
            if (elementIndex > -1) {
              this.dataSource.data.splice(elementIndex, 1);
            }
            if(this.dataSource.data.length==0){
              this.loading=true;
              this.getFirstPage();
            }else{
              this.table.renderRows();
            }
          this.loading = false
          setTimeout(() => {
            this.sellItemSlideToggle['_results'][index].focus()
          }, 0);
        }, (err) => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: err.msg,
              action: 'dismiss',
            },
            duration: 1400
          })
          this._snackBar._openedSnackBarRef.afterDismissed().subscribe(res => {
            item.Status = checked ? 1 : 0
            this.loading = false
          })
        })
      
        }
      })
  }
  else{
      let snack = this._snackBar.openFromComponent(AlertSnackbarComponent, {
        data: {
          ...row,
          msg: `Are you sure you want to ${addOrRemove} this item?`,
          action: 'confirm',
          withCancel: true
        },
        horizontalPosition: 'center',
        verticalPosition: 'top',
      })
      snack.onAction().subscribe(res => {
        this.loading = true
        this.products.activateProduct(row).subscribe(res => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: res.msg,
              action: 'dismiss'
            },
            duration: 1400
          })
          this._snackBar._openedSnackBarRef.afterDismissed().subscribe(res => {
            item.Status = checked ? 1 : 0
            const elementIndex = this.dataSource.data.indexOf(row, 0);
            if (elementIndex > -1) {
              this.dataSource.data.splice(elementIndex, 1);
            }
            if(this.dataSource.data.length==0){
              this.loading=true;
              this.getFirstPage();
            }else{
              this.table.renderRows();
            }
          })
          this.loading = false
        }, (err) => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: err.msg,
              action: 'dismiss',
            },
            duration: 1400
          })
          this._snackBar._openedSnackBarRef.afterDismissed().subscribe(res => {
            item.Status = checked ? 1 : 0
            this.loading = false
          })
        })
      })
      snack.afterDismissed().subscribe(res => {
        if(!res.dismissedByAction) {
          this.loading = true
          item.Status = 1
          this.table.renderRows();
          this.loading = false
          setTimeout(() => {
            this.sellItemSlideToggle['_results'][index].focus()
          }, 0);
        }
      })
    }
  }

  setAvailabilityDate(e, row) {
    let today = new Date()
    today.setDate(today.getDate() + e.value)
    let date = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
    let month = today.getMonth() < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    let year = today.getFullYear()
    let item = this.dataSource.data.find(item => item.MedmateItemCode === row.MedmateItemCode)
    item.stockAvailabilityDate = `${year}-${month}-${date}`
    this.products.setProductsForPharmacy(row).subscribe(res => {
      console.log(res)
      row.editStockAvailableDate = false
    })
  }

  isInStock(checked: boolean, row) {
    let item = this.dataSource.data.find(item => item.MedmateItemCode === row.MedmateItemCode)
    item.Availability = checked ? 1 : 0
    this.products.setProductsForPharmacy(row).subscribe(res => {
      console.log(res)
    })
  }

  toggleEditStock(item) {
    if(this.editStockRef) { //on blur
      item.editStock = false
      item.stockOnHand = parseInt(item.stockOnHand)
      if(!item.stockOnHand || (item.stockOnHand == item.originalStockQty)) {
        item.stockOnHand = item.originalStockQty
        return
      }
      const { editStock, originalStockQty, ...product } = item
      this.products.setProductsForPharmacy(product).subscribe(item => {
        this.loading = true
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: 'Item was updated successfully',
            action: 'dismiss'
          },
          duration: 2400
        })
        this.loading = false
      }, err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: 'There was an error whilst updating this item',
            action: 'dismiss'
          },
        })
        this.loading = false
      })
    } else { //on focus
      item.editStock = true
      setTimeout(() => {
        this.editStockRef.nativeElement.focus();
      }, 0);
    }
  }

  daysLeftDisplayText(date) {
    return this.getDaysRemaining(date) === 1 ? `${this.getDaysRemaining(date)} day left` : `${this.getDaysRemaining(date)} days left`
  }

  getUID(type, uid) {
    const selBox = document.createElement('input');
    selBox.style.opacity = '0'
    selBox.style.position = 'fixed'
    selBox.setAttribute('value', uid);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.open(`${type} Copied to Clipboard`, '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 1300
    });
  }

  getStockValue(stockValue) {
    if(stockValue > 0 || stockValue === null) {
      return 'In Stock'
    } else if(stockValue == 0) {
      return 'Out of Stock'
    }
  }

  editStockAvailableDate(row) {
    if(row.Availability === 1) return
    row.editStockAvailableDate = true
  }
   
  openListPharmaciesDialog(data): void {
    const dialogRef = this.dialog.open(ListPharmaciesDialog, {
      width: '30%',
      data: data,
    })
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.loading = true
        const currentPharmacyCatalogue = result
        this.chosenPharmacy = currentPharmacyCatalogue
        const status = this.inactive === true ? 0 : 1
        this.getPharmacyProducts(
          this.products.getPharmacyProductsForAdmin(
            currentPharmacyCatalogue.locationid, 
            status
          )
        )
        this.ls.setItem('currentPharmacyCatalogue', JSON.stringify(currentPharmacyCatalogue))
      }
    })
  }

  async getPharmacyProducts(result) {
      result.subscribe(async res => {
        this.lastPage = res.last_page;
        this.from = res.from;
        this.to = res.to;
        this.length = res.total
        const products = res['data'];
        if(products.length === 0) {
          this.loading = false
          this.noData = true
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-warn'],
            data: {
              msg: 'No Item Found',
              action: 'dismiss'
            },
            duration: 1400
          })  
          this.searching=false;
        return
        }
        products.forEach(item => {
          item.Cat_Level1 = item.Cat_Level1 !== null ? item.Cat_Level1 : 'Other'
          if(!this.categories1.includes(item.Cat_Level1)) {
            this.categories1.push(item.Cat_Level1)
          }
          if(this.getDaysRemaining(item.stockAvailabilityDate) === 'none') {
            item.Availability = 1
            item.stockAvailabilityDate = null
            this.products.setProductsForPharmacy(item).subscribe(res => {
            }, err => {
              console.log('blahj', err)
            })
          } else if(this.getDaysRemaining(item.stockAvailabilityDate) >= 1) {
            item.Availability = 0
          }
          item.editStockAvailableDate = false
        });
        this.filters = this.filters.concat(this.categories1)
        this.dataSource.data = await products
        this.dataSource.data = this.categories1.map(category => {
          return this.dataSource.data
          .filter(item => item.Cat_Level1 === category)
          .map(item => {
            item.standardPrice = parseFloat(item.standardPrice).toFixed(2)
            return item
          })
        })
        this.dataSource.data = this.dataSource.data.reduce(function(a,b){return a.concat(b);});
        // this.store.dispatch(addPharmacyCatalogueItems(this.dataSource.data))
        this.loading = false
        this.searching = false
        // console.log(this.categories1)
      })
    
  }

  getItemImage() {
    if(this.drawerContent) {
      return `url(${this.drawerContent.PhotoID_s3})`
    }
  }

  getItemName(title:string) {
    if(this.drawerContent) {
      if(title === 'title') {
        return this.drawerContent.ItemName
      }
      if(this.drawerContent.ItemName.length > 38) {
        return `${this.drawerContent.ItemName.substr(0, 38)}...`
      } else {
        return this.drawerContent.ItemName
      }
    }
  }

  getDrawerItemDetails() {
    if(this.drawerContent) {
      return [
        {
          dt: 'EAN',
          dd: this.drawerContent['EAN']
        },
        {
          dt: 'Category',
          dd: this.drawerContent['Cat_Level1']
        },
        {
          dt: 'Sub Category',
          dd: this.drawerContent['Cat_Level2']
        },
        {
          dt: 'Medmate Item Code',
          dd: this.drawerContent['MedmateItemCode']
        }
      ]
    }
  }

  getDrawerPriceDetails() {
    if(this.drawerContent) {
      return [
        {
          title: 'Regular Price',
          price: this.drawerContent.standardPrice ? parseFloat(this.drawerContent.standardPrice).toFixed(2) : null,
        },
        // {
        //   title: 'Sale Price',
        //   price: this.drawerContent.salePrice ? parseFloat(this.drawerContent.salePrice).toFixed(2) : null,
        // },
        {
          title: 'Third Party Price',
          price: this.drawerContent.thirdPartyPrice ? parseFloat(this.drawerContent.thirdPartyPrice).toFixed(2) : null,
        }
      ]
    }
  }

  getDaysRemaining(endDate) {
    if(endDate === null) return 'No date set' 
    const dateToCheck = new Date(endDate)
    //check if endDate is equal to todays date
    
    const oneDay = 1000 * 60 * 60 * 24; //evulate one day 
    const start = Date.UTC(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());
    const end = Date.UTC(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
    if(start <= end) return 'none'
    return (start - end) / oneDay; // get days remaining
  }

  getDrawerStockDetails() {
    if(this.drawerContent) {
      return [
        {
          title: 'Available In Store',
          value: this.drawerContent.Status 
        },
        {
          title: 'In Stock',
          value: this.drawerContent.stockOnHand === null || this.drawerContent.stockOnHand >= 1 ? true : false 
        },
        {
          title: 'Days Left Until In Stock',
          value: this.drawerContent.stockAvailabilityDate ? this.getDaysRemaining(new Date(this.drawerContent.stockAvailabilityDate)) : 'Date not set'
        },
      ]
    }
  }

  openPharmacyPriceInfoDialog() {
    const dialogRef = this.dialog.open(PharmacyPriceInfoDialog, {
      width: '30%',
      maxWidth: '800px'
    })
  }

  syncCatalogueWithUber() {
    this.loading = true
    this.uber.syncCatalogueWithUber(this.chosenPharmacy.locationid).subscribe(res => {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: 'Catalogue updated successfully',
          action: 'dismiss'
        },
        duration: 2000
      })
      this.loading = false
    }, err => {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg: err.error.Error,
          action: 'dismiss'
        },
      })
      this.loading = false
    })
  }
}

/**
 * Edit price dialog
 */
@Component({
  selector: 'edit-price-dialog',
  templateUrl: '../dialogs/edit-price-dialog.html',
  styleUrls: ['../dialogs/edit-price-dialog.scss']
})
export class EditPriceDialog implements OnInit {
  thirdParty = (this.data.hasUber==1&&this.data.uberEatsItem==1&&this.data.uberItem==1)? true: false;

  markup = this.data.PlatformMarkupAmount ? parseFloat(this.data.PlatformMarkupAmount) : 0;
  standardPrice = this.data.standardPrice ? parseFloat(this.data.standardPrice) : 0;
  startingUnitPrice = parseFloat(this.data.standardPrice) <= 0? 0: parseFloat(this.data.standardPrice).toFixed(2);
  startingUberUnitPrice;
  
  medmateUnitPrice = new FormControl(this.startingUnitPrice)
  uberUnitPrice = new FormControl({value: this.data.thirdPartyPrice ? parseFloat(this.data.thirdPartyPrice).toFixed(2) : 0, disabled: true})

  calculatedMedmateCustomerPrice = '0.00'
  calculatedUberCustomerPrice:any = '0.00'
  showConfirm: boolean = false;

  constructor(public dialogRef: MatDialogRef<EditPriceDialog>,@Inject(MAT_DIALOG_DATA) public data, public pharmacyPriceInfoDialog: MatDialog, public dialog: MatDialog) {}
  
  ngOnInit() {
    console.log('data', this.data)
    this.calculatedMedmateCustomerPrice = (parseFloat(this.medmateUnitPrice.value) + this.markup).toFixed(2);
    this.calculatedUberCustomerPrice = (this.uberUnitPrice.value/0.7).toFixed(2);
    this.thirdParty==true? this.uberUnitPrice.enable(): ''
    this.startingUberUnitPrice = this.uberUnitPrice.value
  }
  
  ngAfterViewInit() {
    this.medmateUnitPrice.valueChanges.subscribe(val => {
      this.calculatedMedmateCustomerPrice = (parseFloat(this.medmateUnitPrice.value) + this.markup).toFixed(2);
    })
    this.uberUnitPrice.valueChanges.subscribe(val => {
      this.calculatedUberCustomerPrice = (this.uberUnitPrice.value/0.7).toFixed(2);
    })
  }

  showSyncUberHint(){
    const dialogRef = this.dialog.open(UberSyncHintDialog, {
      width: '400px',
    })
  }
  
  onNoClick(){
    this.dialogRef.close('cancel');
  }

  onYesClick() {
    // console.log(this.basketFiller.value, this.topSeller.value, this.price)
      if(this.thirdParty==true){
        this.data.uberItem = 1;
        this.data.thirdPartyPrice = this.uberUnitPrice.value
      }else{
        this.data.uberItem = 0;
      }
    
    this.data.standardPrice = this.medmateUnitPrice.value != null? this.medmateUnitPrice.value: 0;
    if(this.uberUnitPrice.value!=this.startingUberUnitPrice){
      this.showSyncUberHint()
    }
    this.dialogRef.close(this.data)
  }

  openPharmacyPriceInfoDialog() {
    this.pharmacyPriceInfoDialog.open(PharmacyPriceInfoDialog, {
      width: '30%',
      maxWidth: '800px'
    })
  }
}



export interface Pharmacies {
  partnername?: string 
  OTCCatalogueLocationID?: number
  OTCCatalogueBusinessID?: number 
  locationid?: number
  locationname?: string
  suburb?: string
  postcode?: string
  displaypostcodes?: string
  locationstatus?: number 
  lastOrder?: any
  numberOfOrders?: number 
  phone?: string 
  timings?: string
  state?: string 
}

/**
 * List pharmacies dialog
 */
@Component({
  selector: 'list-pharmacies-dialog',
  templateUrl: '../dialogs/list-pharmacies-dialog.html',
  styleUrls: ['../dialogs/list-pharmacies-dialog.scss']
})
export class ListPharmaciesDialog implements OnInit{
  pharmacyCtrl = new FormControl();
  filteredPharmacies: Observable<Pharmacies[]>;
  options = [];
  searchingItem:boolean =false;
  chosenPharmacy: number
  ready = true;
  @ViewChild('thing') ac: any;
  constructor(public dialogRef: MatDialogRef<ListPharmaciesDialog>,@Inject(MAT_DIALOG_DATA) public data) {

    // this.data = Object.keys(this.data).map((id) => {
    //   return this.data[id]
    // })
    // .filter(pharmacy => pharmacy.locationstatus <= 1)
    // .sort((a,b) => a.locationid - b.locationid)

    // console.log(this.data)
    // console.log('shpowing data', this.data)
    // this.data.filter(pharmacy=>{
    //   return pharmacy.locationstatus<=3
    // }).sort((a,b) => a.locationid - b.locationid)

    
  }
  ngOnInit() {
    this.options = this.data;

    this.filteredPharmacies = this.pharmacyCtrl.valueChanges
      .pipe(
        tap(val=>console.log('working', val)),
        map(state => {if(state.match(/[0-9]+/)&&state.length>1){
          return this._filterPharmacies(state)
        }else if(state.length>3){
          return this._filterPharmacies(state)
        }else{
          return []
        }}
      )
    );
  }
  
  private _filterPharmacies(value: string): Pharmacy[] {
    const filterValue = value.toLowerCase();
    
    return this.options.filter(option => 
      option.locationname.toLowerCase().includes(filterValue) || option.locationid.toString().includes(filterValue)
      );
    }

  handleOption(e) {
    let str = e.option.value.match(/^\d+/)
    this.chosenPharmacy = this.data.find(item => item.locationid === parseInt(str[0]))
  }
}

@Component({
  selector: 'upload-catalogue-dialog',
  templateUrl: '../dialogs/upload-catalogue.html',
  styleUrls: ['../dialogs/upload-catalogue.scss'],
})
export class UploadCatalogueDialog implements OnInit{

  dragOver: boolean = false;
  uploading: boolean = false;
  file: File;
  PMA = new FormControl('1.5');
  admin: boolean = this.data.admin;

  constructor(private dialogRef: MatDialogRef<UploadCatalogueDialog>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public products: ProductsService,
    private _snackBar: MatSnackBar,
    private jwtAuth: JwtAuthService
    ){}

  ngOnInit() {
  }

  log() {
    console.log('hello world')
  }

  onConfirm() {
    this.uploading = true;
    const formData = new FormData();
    formData.append('newData', this.file);
    if(this.admin){
      formData.append('locationid', this.data.chosenPharmacy.locationid);
      formData.append('PMA', this.PMA.value);
    }else{
      formData.append('locationid', this.jwtAuth.getUser().locationid)
    }
    this.products.uploadCatalogue(formData).subscribe(res => {
      this.uploading = false;
      this.close({
        code: 1,
        msg: res.msg,
      });
    }, err => {
      this.uploading = false;
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-red'],
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {
          msg: err.msg,
          action: 'dismiss',
        },
        duration: 2000
      })
    })
  }

  close(data?) {
    this.dialogRef.close(data)
  }

  uploadFile(e) {
    this.file = (e.target as HTMLInputElement).files[0];
    this._snackBar.openFromComponent(AlertSnackbarComponent, {
      panelClass: ['mat-bg-primary'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        msg: 'File added successfully',
        action: 'dismiss',
      },
      duration: 1400
  })
  }

  onDrop(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < 1; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file' && e.dataTransfer.items[i].type === 'text/csv') {
          this.file = e.dataTransfer.items[i].getAsFile();
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-primary'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              msg: 'File added successfully',
              action: 'dismiss',
            },
            duration: 1400
        })
          console.log('list file[' + i + '].name = ' + this.file.name + this.file.type);
        } else {
            this._snackBar.openFromComponent(AlertSnackbarComponent, {
              panelClass: ['mat-bg-primary'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: {
                msg: 'Please check that you have uploaded a file of type .csv',
                action: 'dismiss'
              },
              duration: 2000
          })
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < 1; i++) {
        this.file = e.dataTransfer.files[0]
        console.log('transfer file[' + i + '].name = ' + e.dataTransfer.files[i].name + this.file.type);
      }
  }
}

  onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragOver = true;
  }

  onDragLeave(e){
    this.dragOver = false;
  }
}

@Component({
  selector: 'pharmacy-price-info-dialog',
  // templateUrl: '../dialogs/pharmacy-price-info.html'
  // styleUrls: ['../dialogs/list-pharmacies-dialog.scss'],
  template: `
  <div mat-dialog-title>Price Information</div>
  <div mat-dialog-content>
    <div>
      Prices you confirm here are the product fees that you will receive for the item less service, administration and delivery fees as outlined in the <a target="_blank" class="mm-link mm-text-accent" href="https://medmate.com.au/pricing-commericals/">Medmate Commercials</a>.
    </div><br>
    <div><strong>My Unit Price:</strong> My Unit Price is the amount the pharmacy will be reimbursed for the item.</div>
    <div><strong>Customer Price:</strong> Customer Price is the amount the customer pays for the item including platform administration fees.</div><br>
    <div>Current Third Party Platforms: UberEats</div>
    <div><sup>*</sup>Price can be adjusted if the item is available to be listed on Third Party Platforms.</div> 
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
  </div>
  `
})
export class PharmacyPriceInfoDialog {
  constructor(public dialogRef: MatDialogRef<PharmacyPriceInfoDialog>) {}
  onNoClick() {
    this.dialogRef.close()
  }
}


@Component({
  selector: 'pharmacy-unit-price-dialog',
  template: `
  <div mat-dialog-content>
    <h2>My Unit Price</h2>
    <mat-divider></mat-divider>
    <div class="mm-mt-2">My Unit Price is the amount the pharmacy will be reimbursed for the item.</div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
  </div>
  `
})
export class PharmacyUnitPriceDialog {
  
  constructor(private dialogRef: MatDialogRef<PharmacyUnitPriceDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  onNoClick() {
    this.dialogRef.close()
  }
}

@Component({
  selector: 'uber-sync-hint',
  template: `
  <div mat-dialog-content>
    <h2>Reminder</h2>
    <mat-divider></mat-divider>
    <div class="mm-mt-2">Please use the <b>Sync With Uber</b> button to sync the Uber store catalogue with all price updates.</div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
  </div>
  `
})
export class UberSyncHintDialog {
  constructor(private dialogRef: MatDialogRef<UberSyncHintDialog>){}
  onConfirm(){
    this.dialogRef.close()
  }
}

@Component({
  selector: 'markup-dialog',
  template: `
  <div mat-dialog-content>
    <h2>Customer Price</h2>
    <mat-divider></mat-divider>
    <div class="mm-mt-2">Customer Price is the amount the customer pays for the item including platform administration fees.</div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
  </div>
  `
})
export class MarkupDialog {
  

  constructor(private dialogRef: MatDialogRef<MarkupDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  onNoClick() {
    this.dialogRef.close()
  }
}

@Component({
  templateUrl: '../dialogs/activate-item.html',
  styleUrls: ['../dialogs/activate-item.scss']
})
export class ActivateItemDialog implements OnInit {
  
  basketFiller = new FormControl(false)
  topSeller = new FormControl(false)
  thirdParty = new FormControl((this.data.hasUber==1&&this.data.uberEatsItem==1&&this.data.uberItem==1)?true:false)
  subscription: any;
  
  markup = this.data.PlatformMarkupAmount? parseFloat(this.data.PlatformMarkupAmount) : 0;
  standardPrice = this.data.standardPrice? parseFloat(this.data.standardPrice): 0;
  startingUnitPrice = parseFloat(this.data.standardPrice) <= 0? 0: parseFloat(this.data.standardPrice).toFixed(2);
  startingUberUnitPrice;
  
  medmateUnitPrice = new FormControl(this.startingUnitPrice)
  uberUnitPrice = new FormControl(this.data.thirdPartyPrice?this.data.thirdPartyPrice:0)
  
  calculatedMedmateCustomerPrice = (parseFloat(this.medmateUnitPrice.value) + this.markup).toFixed(2);
  calculatedUberCustomerPrice = (this.uberUnitPrice.value/0.7).toFixed(2);
  
  constructor(private dialogRef: MatDialogRef<ActivateItemDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {}
  
  ngOnInit() {
    console.log(this.data);
    if(this.thirdParty.value == false){
      const uberRow = document.getElementsByClassName("uber") as HTMLCollectionOf<HTMLElement>;
      Array.from(uberRow).forEach(item => {
        item.style.display = 'none'
      })
    }
    this.startingUberUnitPrice = this.uberUnitPrice.value;
  }
  
  ngAfterViewInit() {
    const uberRow = document.getElementsByClassName("uber") as HTMLCollectionOf<HTMLElement>;
    this.medmateUnitPrice.valueChanges.subscribe(val => {
      this.calculatedMedmateCustomerPrice = (this.medmateUnitPrice.value + this.markup).toFixed(2);
    })
    this.uberUnitPrice.valueChanges.subscribe(val => {
      this.calculatedUberCustomerPrice = (this.uberUnitPrice.value/0.7).toFixed(2);
    })
    this.subscription = this.thirdParty.valueChanges.subscribe(value => {
      if(value == true){
        Array.from(uberRow).forEach(item => {
          item.style.display = 'block'
        })
      }else if(value == false){
        Array.from(uberRow).forEach(item=>{
          item.style.display = 'none'
        })
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openMyPriceDialog() {
    const dialogRef = this.dialog.open(PharmacyUnitPriceDialog, {
      data: {
        msg: '' 
      },
      width: '400px',
      maxWidth: '400px'
    });
  }

  openMarkupDialog() {
    const dialogRef = this.dialog.open(MarkupDialog, {
      data: {
        msg: '' 
      },
      width: '400px',
      maxWidth: '400px'
    });
  }

  showSyncUberHint(){
    const dialogRef = this.dialog.open(UberSyncHintDialog, {
      width: '400px',
    })
  }

  onNoClick() {
    this.dialogRef.close("cancel")
  }
  onYesClick() {
    // console.log(this.basketFiller.value, this.topSeller.value, this.price)
    this.data.BasketFiller = this.basketFiller.value == true? 1 : 0;
    this.data.TopSellerOrder = this.topSeller.value == true? 1: 0;
    if(this.thirdParty.value){
      if(this.thirdParty.value==true){
        this.data.uberItem = 1;
        this.data.thirdPartyPrice = this.uberUnitPrice.value
      }else{
        this.data.uberItem = 0;
      }
    }
    this.data.standardPrice = this.medmateUnitPrice.value != null? this.medmateUnitPrice.value: 0;
    // console.log(this.data)
    if(this.uberUnitPrice.value != this.startingUberUnitPrice){
      this.showSyncUberHint()
    }
    this.dialogRef.close(this.data)
  }
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { SupportService } from 'app/shared/services/support/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportComponent implements OnInit {
  supportForm: FormGroup;
  successMessage: string;
  selectedFile: File;
  submitting: boolean;

  constructor(
    private _snackBar: MatSnackBar,
    private jwtAuthService: JwtAuthService,
    private supportService: SupportService
  ) { }

  ngOnInit(): void {
    this.supportForm = new FormGroup({
      typeOfRequest: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      mobileNumber: new FormControl('', Validators.required)
    });
  }

  get phoneNumber() {
    const domainNames = ['dds', 'amcal'];
    const businessIds = [2, 4];
    const userBusinessId = this.jwtAuthService.getUser().businessid;

    const isValidDomain = domainNames.some(domainName => window.location.hostname.includes(domainName));
    const isValidBusinessId = businessIds.includes(userBusinessId);

    if (isValidBusinessId || isValidDomain) {
      return '1300 364 996';
    }

    return '1300 858 909';
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.supportForm.get(controlName);
    return control.invalid && control.touched;
  }

  onFileSelected(event): void {
    this.selectedFile = event.target.files[0];
  }

  handleSubmit(form: FormGroup, formDirective: FormGroupDirective) {
    this.submitting = true;
    // Construct the FormData object and append files (if any)
    const formData = new FormData();
    if (this.selectedFile) {
      formData.append('attachment', this.selectedFile, this.selectedFile.name);
    }
    formData.append('request_type', `${form.value['typeOfRequest']} - ${new Date()}`);
    formData.append('email', `${form.value['email']}`);
    formData.append('phone', `${form.value['mobileNumber']}`);
    formData.append('description', `${form.value['description']}`);
    formData.append('location_id', this.jwtAuthService.getUser().locationid);
    formData.append('business_id', this.jwtAuthService.getUser().businessid);
    formData.append('source', 'Medmate-Portal');

    this.successMessage = 'Thank you for contacting support. We will be in touch with you as soon as possible.';

    // this.supportService.sendEmail(formData).subscribe(res => {
    this.supportService.contact_us(formData).subscribe(res => {
      // Reset the form and clear the selected file
      formDirective.resetForm();
      this.selectedFile = undefined;
      this.submitting = false;
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: this.successMessage,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 6000
      });
    }, err => {
      this.submitting = false;
      console.log(err);
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mat-bg-warn'],
        data: {
          msg: err,
          action: 'dismiss'
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 6000
      })
    });
  }
}
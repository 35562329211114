export const PRODUCT_CATEGORY_MAP = {
'Baby':1,
'Beauty':2,
'General Health':3,
'Home Health Care':4,
'Household':5,
'Medicines':6,
'Personal Care':7,
'Prescriptions':8,
'Vitamins & Supplements':9,
'Top Sellers':10,
'Healthfoods':11,
'Allergy & Sinus':12,
'Cold and Flu':13,
'Children\'s Medicine':14,
'Confectionary & General Items':15,
'Cough, Cold and Flu':16,
'Dental':17,
'First Aid':18,
'Hygiene':19,
'Pain Relief':20,
'Sexual Health & Family Planning':21,
'Skincare & Cosmetics':22,
'Stomach & Gastrointestinal':23,
'Eye & Ear Care':24,
'Sunscreen & Lip care':25,
'Candles & Diffusers':26,
'Diet & Weight Loss':27,
'Test Kits':28,
}
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
  CanActivateChild,
} from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";

@Injectable()
export class UserRoleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private jwtAuth: JwtAuthService, private snack: MatSnackBar) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let url: string = state.url;
    return this.checkAccess(next, url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  checkAccess(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.jwtAuth.isLoggedIn()) {
      const role = this.jwtAuth.getUser().role;
      if (route.data.role && route.data.role.indexOf(role) === -1) {
        this.router.navigate(['/access-denied']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/sessions/signin']);
    return false;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError, Observable } from "rxjs";
import { environment } from "environments/environment";
import { SessionStorageService } from "../session-storage.service";
import { config } from "config";

export type UserRole = 'Admin' | 'Portal User' | 'User' | 'Business User'
@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = (new BehaviorSubject<User>(this.user));
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "EGRET_USER";

  constructor(
    private st: SessionStorageService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');
  }

  public signin(username, password) {
    this.signingIn = true;
    return this.http.post(`${environment.apiURL}login`, { username, password })
      .pipe(
        map((res: any) => {
          const { userid, profiletypeid, firstname, ...details } = res.success.user_details
          var role = null
          if(profiletypeid == 7) {
            role = 'Admin'
          } else if(profiletypeid == 3) {
            role = 'User'
          } else if(profiletypeid == 12) {
            role = 'Portal User'
          } else if (profiletypeid == 13) {
            role = 'Business User'
          } else if (profiletypeid == 14) {
            role = 'Driver'
          }
          let user = {
            id: userid,
            displayName: firstname,
            details: details,
            businessid: res.success['_business'],
            locationid: res.success['_location'],
            location: res.success['location '],
            role: role,
          }
          this.setUserAndToken(res.success.token, user, !!res);
          this.signingIn = false;
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public resetPassword(data) {
    return this.http.post(config.apiUrl+ 'forgotpassword', data)
    // return this.http.post(`${environment.apiURL}forgot_password`, data)
    .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    return of(this.getUser())
      .pipe(
        map((profile: User) => {
          this.setUserAndToken(this.getJwtToken(), profile, true);
          this.signingIn = false;
          return profile;
        }),
        catchError((error) => {
          return of(error);
        })
      );
    
    /*
      The following code get user data and jwt token is assigned to
      Request header using token.interceptor
      This checks if the existing token is valid when app is reloaded
    */

    // return this.http.get(`${environment.apiURL}/api/users/profile`)
    //   .pipe(
    //     map((profile: User) => {
    //       this.setUserAndToken(this.getJwtToken(), profile, true);
    //       return profile;
    //     }),
    //     catchError((error) => {
    //       this.signout();
    //       return of(error);
    //     })
    //   );
  }

  get role(): UserRole {
    return this.getUser().role
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.st.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.st.getItem(this.APP_USER);
  }

  setUserAndToken(token: String, user, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user
    this.user$.next(user);
    this.st.setItem(this.JWT_TOKEN, token);
    this.st.setItem(this.APP_USER, this.user);
  }
  isAdmin() {
    return this.user.role === 'Admin'
  }
  isBusiness() {
    return this.user.role === 'Business User'
  }

  isDriver() {
    return this.user.role === 'Driver'
  }

  isPortalUser() {
    return this.user.role === 'Portal User'
  }
  
  register(data): Observable<any> {
    return this.http.post(`${config.apiUrl1}register`, data).pipe(
       catchError(err => {
         return throwError(err)
       })
     )
  }

  login(username, type){
    let data = {
      username: username,
      type: type
    }
    return this.http.post(`${config.apiUrl1}login`, data).pipe(
      catchError(err=>{
        return throwError(err)
      })
    )
  }

  referUser(data): Observable<any> {
    return this.http.post(`${config.apiUrl1}referUser`, data).pipe(
       catchError(err => {
         return throwError(err)
       })
     )
     }

     addPharmacyUser(data): Observable<any> {
      return this.http.post(`${config.apiUrl}addPharmacyUser`, data).pipe(
        catchError(err => {
          return throwError(err)
        })
      )
     }


    }

